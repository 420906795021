import React from "react"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import algoliasearch from "algoliasearch/lite"
import {
    InstantSearch,
    Hits,
    SearchBox,
    Pagination
} from 'react-instantsearch-dom'

import Layout from "../../components/layout/layout"
import PageTransition from "../../components/transition/page"
import SEO from "../../components/seo/seo"

import "./search.scss"

class SearchPage extends React.Component {

    constructor(props) {
      super(props)
      this.pageTransition = React.createRef()
    }

    animate() {
      this.pageTransition.current.play()
    }

    render() {

      const { path } = this.props

      const searchClient = algoliasearch(
        'VM8TMF2ZNB',
        '72235719a13eea092b46fffa2a846efd'
      )

      return (
        <Layout theme="light" path={path} exit={{ length: 1.7, trigger: () => this.animate() }} entry={{ delay: 0.9, length: 1 }}>
          <SEO title="Festivals" />
          <section className={"container mx-auto relative"}>
            <InstantSearch indexName="DropSearch" searchClient={searchClient}>
                <div className={"flex"}>
                    <SearchBox />
                </div>
                
                <div className={"flex flex-wrap"}>
                    <Hits hitComponent={Hit} />
                </div>
                <Pagination />
            </InstantSearch>
          </section>
          <TransitionPortal>
            <PageTransition ref={this.pageTransition} />
          </TransitionPortal>
        </Layout>
      )
    }

}

function Hit(props) {
    
    const { fields, sys } = props.hit

    if(sys.type !== "Entry"){
        return null
    }else{
        return (
            <div>
              {fields.title['en-AU'] && <span>{fields.title['en-AU']}</span>}
            </div>
        )
    }
    
}


export default SearchPage
